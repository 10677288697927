import Api from "@/config/Api";

export default {
  getSubscriptions() {
    return Api().get(`/subscriptions`, {});
  },
  trialToSubscription() {
    return Api().post(`/subscriptions/trial`, {});
  },
  paySubscription(data) {
    return Api().post("/subscriptions/pay", data);
  },
  payPpSubscription(data) {
    return Api().post("/subscriptions/pp/pay", data);
  },
  checkoutSubscription(data) {
    return Api().post("/subscriptions/checkout", data);
  },
  checkoutPpSubscription(data) {
    return Api().post("/subscriptions/pp/checkout", data);
  },
  downloadInvoiceSubscription(subscriptionId) {
    return Api().get(`/subscriptions/download/invoices/${subscriptionId}`, {
      responseType: "arraybuffer"
    });
  },
  membershipCheckout(data) {
    return Api().post("/checkout", data);
  },

  membershipCancel(userId) {
    return Api().get(`/cancelplan/${userId}`, {});
  },
  getPayAdditionals() {
    return Api().get(`/additional`, {});
  },
  postPayAdditional(data) {
    return Api().post(`/additional/checkout`, data);
  }
};
